import React from "react"

const Checkout = class extends React.Component {
  componentDidMount() {
    this.stripe = window.Stripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
  }

  async redirectToCheckout(event) {
    event.preventDefault()
    const { error } = await this.stripe.redirectToCheckout({
      items: [{ sku: "sku_HGSYyts8ve9nce", quantity: 1 }],
      successUrl: `${window.location.origin}/payment-success/`,
      cancelUrl: `${window.location.origin}/`,
    })

    if (error) {
      console.warn("Error:", error)
    }
  }

  render() {
    return (
      <button
        className='btn btn-primary btn-lg'
        onClick={event => this.redirectToCheckout(event)}
      >
        Make a Deposit
      </button>
    )
  }
}

export default Checkout
