import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Checkout from "../components/checkout"
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';

export const paymentQuery = graphql`
  query paymentPageQuery($uid: String) {
    prismic {
      allPayment_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            meta_title
            meta_keywords
            meta_description
            payment_title
            payment_content
          }
        }
      }
    }
  }
`

const RenderBody = ({ paymentPage }) => {
  return (
    <React.Fragment>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 mt-4'>
            <div className='panel panel-default text-center'>
              <div className='panel-heading p-5'><h1>{paymentPage.payment_title}</h1></div>
              <div className='panel-body p-5'>
                {RichText.render(paymentPage.payment_content, linkResolver)}
                <Checkout />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const PaymentPage = props => {
  const doc = props.data.prismic.allPayment_pages.edges.slice(0,1).pop();
  if(!doc) return null;

  return (
    <Layout>
      <SEO title={doc.node.meta_title} keywords={doc.node.meta_keywords} description={doc.node.meta_description} lang='en-us' />
      <RenderBody paymentPage={doc.node} />
    </Layout>
  )
}
export default PaymentPage
